import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  background: black;
  height: 80px;
  margin-top: 0px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  padding: .5rem calc((130vw - 10px) / 10);
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 100;
  top: 0;
  position: relative;

  @media screen and (max-width: 1060px) {
    transition: 1s all ease;
  }
  @media screen and (max-width: 820px) {
    padding-bottom: 70px;
    display: block;
    padding-right: 0;
    padding-left: 0;
    padding-top: 50px;
  }
`;

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  
  &:hover {
    scale: 1.3;
    originX: 0;
    transition: 0.4s all ease;

  }
  &.active {
    color: #FFD700;
  }

`;


export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  white-space: nowrap; 
  font-weight: 600;
  font-size: 20px;
  @media screen and (max-width: 820px) {
    margin-top: 30px;
    margin-right: 100px;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media screen and (max-width: 820px) {
    display: none;
  }
`;


export const LogoText = styled.div`
  margin-left: 20px;
  color: #FFD700;
  font-weight: 700;
  font-size: 30px;
  white-space: nowrap; 
  @media screen and (max-width: 820px) {
    font-size: 20px;
    opacity: 0.4;
  }
`;
