import React from 'react'

import Navbar from '../components/Navbar';
import OurGames from '../components/OurGames/our-games';


  const Our_Games = () => {

    return (
        <>

            <Navbar />
            <OurGames />

        </>

    )
}

export default Our_Games;