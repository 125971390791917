import React from 'react'

import Navbar from '../components/Navbar';
import ToS from '../components/ToS/tos';


  const TOS = () => {
    return (
        <>
            <Navbar />
            <ToS />
        </>

    )
}

export default TOS;