import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import Hazari from '../../images/hazari.png';
import Callbreak from '../../images/callbreak.png';
import Twentynine from '../../images/twentynine.png';
import Googleplay from '../../images/google-play-badge.png';
//Elements import

import '../OurGames/our-games.css';

const ourGames= () => {
  return (

    <div className='backGround'>
      <div className='yellowLine'></div>
      <section className='banner' id='home' >

      <div className='container'>
            <div className='leftSide'>
              
              <a href='https://play.google.com/store/apps/details?id=com.livegames.hazari' target="_blank" rel="noopener noreferrer">
                <div className='imgBox'>
                  <img src={Hazari} alt="Hazari" />
                </div>
              </a>  

              <a href='https://play.google.com/store/apps/details?id=com.livegames.callbreak' target="_blank" rel="noopener noreferrer">
                <div className='imgBox'>
                  <img src={Callbreak} alt="Call Break" />
                </div>
              </a>

              <a href='https://play.google.com/store/apps/details?id=com.livegames.twentynine' target="_blank" rel="noopener noreferrer">
                <div className='imgBox'>
                  <img src={Twentynine} alt="29 Classic" target="_blank" rel="noopener noreferrer"/>
                </div>
              </a>
            </div>


            <div className='rightSide'>

                  <div className='text'>
                    Download Our Games
                  </div>

                  <a href='https://play.google.com/store/apps/dev?id=9110317039186383675' target="_blank" rel="noopener noreferrer">
                    <div className='googlePlayBox'>
                      <img src={Googleplay} alt="Google Play Store" />
                    </div>
                  </a>
            </div>

      </div>


      </section>
    </div>

  )
}

export default ourGames;


