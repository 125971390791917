import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom";
import OurGames from './pages/our-games';
import TOS from './pages/Tos';
import PrivacyPolicy from './pages/Privacy-policy';

function App(){


        return(
            <>

            <Router>
                <Routes>
                    <Route exact path="/" element={<OurGames/>} />
                </Routes>
                <Routes>
                    <Route path="/our-games" element={<OurGames/>} />
                </Routes>
                <Routes>
                    <Route path="/tos" element={<TOS/>} />
                </Routes>
                <Routes>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                </Routes>
            </Router>
            
            </>

        )
    
}

export default App;