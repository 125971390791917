import React from 'react';
import {
  Nav,
  NavLink,
  NavMenu,
  LogoText
} from './NavbarElements';

const Navbar = () => {

  return (
    <>
      <Nav>
        <NavLink to='/our-games'>

            <img src={require('../../images/logo.png')} alt='logo' />
            <LogoText>
                Live Game Labs
            </LogoText>

        </NavLink>

        <NavMenu>
          <NavLink to='/our-games' activeStyle>
            Our Games
          </NavLink>
          <NavLink to='/tos' activeStyle>
            TOS
          </NavLink>
          <NavLink to='/privacy-policy' activeStyle>
            Privacy Policy
          </NavLink>

        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;