import React from 'react'

import Navbar from '../components/Navbar';
import Privacy from '../components/PrivacyPolicy/privacy';

  const PrivacyPolicy = () => {
    
    return (
        <>
            <Navbar />
            <Privacy />
        </>

    )
}

export default PrivacyPolicy;